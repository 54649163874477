import React from 'react';
// import { Link } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
// //Slice
// import { setSelectedSources as setSelectedSourcesAction } from '../../app/features/librarySlice';
// // Hooks
// import { useLibrary } from '../../hooks/useLibrary';
// // Services
// import { sendUserInformation } from '../../services/sendUserInformation';
// Styles
import './DataBaseSource.css';

const DataBaseSource = ({ dataBase }) => {
	// const dispatch = useDispatch();
	// Hooks
	// const { getLibraryDataSocket } = useLibrary();
	
	// Slices
	// const { user_email } = useSelector((state) => state.user);
	// const { resourceType } = useSelector((state) => state.metasearch);
	// const { institutionCode } = useSelector((state) => state.institution);
	// const { startYear, endYear, query, searchType } = useSelector(
	// 	(state) => state.library
	// );

	// const searchDataBase = (name) => {
	// 	dispatch(setSelectedSourcesAction([name]));
	// 	if (query) {
	// 		getLibraryDataSocket(
	// 			query,
	// 			1,
	// 			false,
	// 			startYear,
	// 			endYear,
	// 			[name],
	// 			[],
	// 			searchType,
	// 			false
	// 		);
	// 		// Envio de datos de busqueda y de usuario
	// 		sendUserInformation(
	// 			resourceType,
	// 			name.toLowerCase(),
	// 			user_email,
	// 			institutionCode
	// 		);
	// 	}
	// };

	if (dataBase.name !== 'DYNAMIC') {
		return (
			// <Link to='/library'>
				<button
					className='dataBaseButton'
					// onClick={() => searchDataBase(dataBase.name)}
				>
					<img
						loading='lazy'
						className='dataBaseImg'
						src={`https://${dataBase.path}`}
						alt={dataBase.name}
					/>
				</button>
			// </Link>
		);
	} else {
		return (
			<img
				loading='lazy'
				className='dataBaseImg'
				src={`https://${dataBase.path}`}
				alt={dataBase.name}
			/>
		);
	}
};

export default DataBaseSource;
