import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// Components
import Loading from '../Loading';
// utils
import { decimalToText } from '../../utils/decimalToText';
// Styles
import './NavigationBarModules.css';

const NavigationBarModules = ({
  active,
  type,
  resource,
  metasearcher,
  setActiveBar,
}) => {
  const { wordFetched } = useSelector((state) => state.metasearch);
  const { totalData, isLibraryLoadingMoreData } = useSelector(
    (state) => state.library
  );
  const { totalBooks, isBooksLoadingMoreData } = useSelector(
    (state) => state.books
  );
  const { total_multimedia, is_multimedia_loading } = useSelector(
    (state) => state.multimedia
  );
  const { total_news_query, is_news_loading } = useSelector(
    (state) => state.news
  );
  const { totalPatents, isLoadingMoreData } = useSelector(
    (state) => state.patents
  );
  const { elementaryData, medicalData, thesaurusData } = useSelector(
    (state) => state.dictionary
  );
  const { isMedicalInstitution } = useSelector((state) => state.institution);

  const getTotalDictionary = () => {
    if (isMedicalInstitution) {
      return (typeof elementaryData[0] === 'string' ||
        elementaryData.length === 0) &&
        (typeof medicalData[0] === 'string' || medicalData.length === 0) &&
        (typeof thesaurusData[0] === 'string' || thesaurusData.length === 0)
        ? 0
        : 1;
    } else {
      return (typeof elementaryData[0] === 'string' ||
        elementaryData.length === 0) &&
        (typeof thesaurusData[0] === 'string' || thesaurusData.length === 0)
        ? 0
        : 1;
    }
  };

  const getStatus = (resource) => {
    switch (resource) {
      case 'articles':
        return {
          loading: isLibraryLoadingMoreData,
          total: totalData,
        };
      case 'books':
        return {
          loading: isBooksLoadingMoreData,
          total: totalBooks,
        };
      case 'media':
        return {
          loading: is_multimedia_loading,
          total: total_multimedia,
        };
      case 'news':
        return {
          loading: is_news_loading,
          total: total_news_query,
        };
      case 'patents':
        return {
          loading: isLoadingMoreData,
          total: totalPatents,
        };
      case 'dictionary':
        return {
          loading: isLoadingMoreData,
          total: getTotalDictionary(),
        };
      default:
        return null;
    }
  };

  const statusResource =
    type === 'metasearcher' ? getStatus(resource.route) : null;

  return (
    <Link
      to={resource.route}
      className={active ? 'activeSection' : ''}
      onClick={() => setActiveBar(false)}
    >
      <img src={resource.icon} alt='' />
      <p>{resource.title}</p>
      {metasearcher && wordFetched ? (
        statusResource?.loading && statusResource?.total === 0 ? (
          <Loading color={active ? 'green' : 'black'} />
        ) : (
          <p>{decimalToText(statusResource?.total)}</p>
        )
      ) : null}
    </Link>
  );
};

export default NavigationBarModules;
