import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  topics: [],
  databases: [],
  institutionCode: '',
  institutionLogo: '',
  isMedicalInstitution: false,
  requestLogin: false,
  uniqueLogin: false,
  assignedLogin: ['canvas', 'unique'],
};

const institutionSlice = createSlice({
  name: 'institution',
  initialState,
  reducers: {
    setTopics: (state, action) => {
      state.topics = action.payload;
    },
    setDatabases: (state, action) => {
      state.databases = action.payload;
    },
    setInstitutionCode: (state, action) => {
      state.institutionCode = action.payload;
    },
    setInstitutionLogo: (state, action) => {
      state.institutionLogo = action.payload;
    },
    setIsMedicalInstitution: (state, action) => {
      state.isMedicalInstitution = action.payload;
    },
    setRequestLogin: (state, action) => {
      state.requestLogin = action.payload;
    },
    setUniqueLogin: (state, action) => {
      state.uniqueLogin = action.payload;
    },
    setAssignedLogin: (state, action) => {
      state.assignedLogin = action.payload;
    },
  },
});

export const {
  setTopics,
  setDatabases,
  setInstitutionCode,
  setInstitutionLogo,
  setIsMedicalInstitution,
  setRequestLogin,
  setUniqueLogin,
  setAssignedLogin,
} = institutionSlice.actions;

export default institutionSlice.reducer;
