import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Pages
import appPages from './pages';
// Utils
import { fetchWithoutToken } from '../../utils/fetch';
// Slice
import { setInterestingLinks } from '../../app/features/interestingLinksSlice';
import { setAllArtSources } from '../../app/features/artSlice';
import { setAllBooksSources } from '../../app/features/booksSlice';
import { setAllPatentsSources } from '../../app/features/patentsSlice';
import { setAllLibrarySources } from '../../app/features/librarySlice';
import { setBaseNews, setResetBaseNews } from '../../app/features/newsSlice';
import {
  setPublicToken,
  setPublicTokenExpires,
} from '../../app/features/authSlice';
import {
  setDatabases,
  setInstitutionCode,
  setInstitutionLogo,
  setTopics,
  setRequestLogin,
  setUniqueLogin,
} from '../../app/features/institutionSlice';
// Services
import { getNews } from '../../services/getNews';
import { getInterestingLinks } from '../../services/getInterestingLinks';
import { getInstitutionTopicsAndDatabases } from '../../services/getInstitutionTopicsAndDatabases';

const Router = () => {
  const dispatch = useDispatch();

  // Datos de autenticación
  const USERNAME = process.env.REACT_APP_AUTH_USERNAME;
  const PASSWORD = process.env.REACT_APP_PASSWORD;
  const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
  const INSTITUTION_CODE = process.env.REACT_APP_INSTITUTION_CODE;

  // Slices
  const { institutionCode } = useSelector((state) => state.institution);

  const loadInstitutionData = async (code) => {
    try {
      const newsResponse = await getNews(code);
      dispatch(setResetBaseNews());
      Object.values(newsResponse.data).forEach((category) => {
        const newsItems = category[Object.keys(category)[0]].data;
        dispatch(setBaseNews(newsItems));
      });
    } catch (error) {
      dispatch(setBaseNews([]));
    }

    try {
      const resourcesResponse = await getInterestingLinks(code);
      dispatch(setInterestingLinks(resourcesResponse.data.data));
    } catch (error) {
      dispatch(setInterestingLinks([]));
    }

    try {
      const topicsResponse = await getInstitutionTopicsAndDatabases(code);
      dispatch(setTopics(topicsResponse.data.categories));
      dispatch(setDatabases(topicsResponse.data.sources));
    } catch (error) {
      dispatch(setTopics([]));
      dispatch(setDatabases([]));
    }
  };

  const isTokenExpired = () => {
    const expiresIn = localStorage.getItem('expires_in');
    if (!expiresIn) return true;

    const expirationDate = new Date(expiresIn);
    const currentDate = new Date();
    return currentDate >= expirationDate;
  };

  const authenticateUser = async () => {
    try {
      const response = await fetchWithoutToken.post('/auth', {
        username: USERNAME,
        password: PASSWORD,
        code: ENVIRONMENT === 'PRODUCTION' ? null : INSTITUTION_CODE,
      });

      const {
        access_token,
        expires_in,
        code,
        logo,
        requestLoginInstitution,
        uniqueLoginInstitution,
        // all sources
        academic_sources,
        books_sources,
        gallery_sources,
        patents_sources,
      } = response.data;

      const uniqueBooksSources = [...new Set(books_sources)];

      localStorage.setItem('token', access_token);
      localStorage.setItem('expires_in', expires_in);
      if (code !== institutionCode || isTokenExpired()) {
        dispatch(setInstitutionCode(code));
        dispatch(setInstitutionLogo(logo));
        dispatch(setPublicToken(access_token));
        dispatch(setPublicTokenExpires(expires_in));
        dispatch(setRequestLogin(requestLoginInstitution));
        dispatch(setUniqueLogin(uniqueLoginInstitution));
        dispatch(setAllLibrarySources(academic_sources));
        dispatch(setAllBooksSources(uniqueBooksSources));
        dispatch(setAllArtSources(gallery_sources));
        dispatch(setAllPatentsSources(patents_sources));
        //  Solicitando datos de la institucion
        await loadInstitutionData(code);
      }
    } catch (error) {
      console.error('Error during authentication', error);
    }
  };

  useEffect(() => {
    authenticateUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [institutionCode]);

  const pageRoutes = appPages.map(({ path, title, element }) => {
    return <Route key={title} path={`/${path}`} element={element} />;
  });

  return <Routes>{pageRoutes}</Routes>;
};

export default Router;
