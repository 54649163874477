import React from 'react';
// Redux
import {
  useSelector,
  // useDispatch
} from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
// Slice
// import { setActiveLoginModal } from '../../app/features/userSlice';
// Style
import './LoginHome.css';

const LoginHome = () => {
  // const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { navigate } = useNavigate();

  // const { requestLogin } = useSelector((state) => state.institution);
  const { user_email, user_name } = useSelector((state) => state.user);

  const currentlyRoute = pathname.replace('/', '');

  const userLogged = () => {
    return <button className='loginButton'>Welcome, {user_name}</button>;
  };

  const userNotLogged = () => {
    if (currentlyRoute === '' || currentlyRoute === 'search') {
      // console.log('SOLICITANDO DATA');
    } else {
      navigate('/search');
    }
  };

  // if (requestLogin) {
  if (false) {
    if (user_email?.length > 0) {
      return userLogged();
    } else {
      return userNotLogged();
    }
  } else {
    return <button className='loginButton noLogin'>Login</button>;
  }
};

export default LoginHome;
