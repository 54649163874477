import { fetchWithoutToken } from '../utils/fetch';

export const getInfoUserMoodle = async (codeParam, institutionCode) => {
	const getCredentials = () => {
		switch (institutionCode) {
			case 'CMVC':
				return {
					client_id: process.env.REACT_APP_CLIENTID_CMVC_MOODLE,
					client_secret: process.env.REACT_APP_CLIENTSECRET_CMVC_MOODLE,
				};
			case 'GAPAT':
				return {
					client_id: process.env.REACT_APP_CLIENTID_GAPAT,
					client_secret: process.env.REACT_APP_CLIENTSECRET_GAPAT,
				};
			case 'AMHEALTH':
				return {
					client_id: process.env.REACT_APP_CLIENTID_AMHEALTH,
					client_secret: process.env.REACT_APP_CLIENTSECRET_AMHEALTH,
				};
			case 'ABC':
				return {
					client_id: process.env.REACT_APP_CLIENTID_ABC,
					client_secret: process.env.REACT_APP_CLIENTSECRET_ABC,
				};
			case 'UNAD':
				return {
					client_id: process.env.REACT_APP_CLIENTID_UNAD,
					client_secret: process.env.REACT_APP_CLIENTSECRET_UNAD,
				};
			default:
				return {
					client_id: process.env.REACT_APP_CLIENTID_CMVC_MOODLE,
					client_secret: process.env.REACT_APP_CLIENTSECRET_CMVC_MOODLE,
				};
		}
	};

	try {
		const res = await fetchWithoutToken.post(
			`/institutions/${institutionCode}/lms-auth`,
			{
				client_id: getCredentials().client_id,
				client_secret: getCredentials().client_secret,
				code: codeParam,
			},
			{
				headers: {
					Authorization: `Bearer ${localStorage.token}`,
				},
			}
		);
		return res;
	} catch (error) {
		console.error('Your authorization token has expired or is incorrect.');
		return null;
	}
};
